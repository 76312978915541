import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTransactions, handleError } from '../../../sevices/apiService'

export const getTransactionsContent = createAsyncThunk('/transactions/content', async (page) => {
  try {
    const response = await getTransactions(page)
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    isLoading: false,
    transactions: []
  },
  reducers: {},

  extraReducers: {
    [getTransactionsContent.pending]: state => {
      state.isLoading = true
    },
    [getTransactionsContent.fulfilled]: (state, action) => {
      state.transactions = action.payload
      state.isLoading = false
    },
    [getTransactionsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

// export const { addNewTransaction } = transactionsSlice.actions

export default transactionsSlice.reducer