import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPayoutOptions, handleError } from '../../../sevices/apiService'

export const getPayoutOptionsContent = createAsyncThunk('/payoutoptions/content', async () => {
  try {
    const response = await getPayoutOptions()
    console.log(response.data);
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const payoutOptionsSlice = createSlice({
  name: 'payoutsoptions',
  initialState: {
    isLoading: false,
    payoutsoptions: []
  },
  reducers: {},

  extraReducers: {
    [getPayoutOptionsContent.pending]: state => {
      state.isLoading = true
    },
    [getPayoutOptionsContent.fulfilled]: (state, action) => {
      state.payoutsoptions = action.payload
      state.isLoading = false
    },
    [getPayoutOptionsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewPayoutOption } = payoutOptionsSlice.actions

export default payoutOptionsSlice.reducer