import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetItems, handleError } from '../../../sevices/apiService'

export const adminGetItemsContent = createAsyncThunk('/payouts/content', async () => {
  try {
    const response = await adminGetItems()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminItemsSlice = createSlice({
  name: 'adminItems',
  initialState: {
    isLoading: false,
    adminItems: []
  },
  reducers: {},

  extraReducers: {
    [adminGetItemsContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetItemsContent.fulfilled]: (state, action) => {
      state.adminItems = action.payload
      state.isLoading = false
    },
    [adminGetItemsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const adminAddNewItem = adminGetItemsContent.actions

export default adminItemsSlice.reducer