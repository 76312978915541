import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetCategories, handleError } from '../../../sevices/apiService'

export const adminGetCategoriesContent = createAsyncThunk('/adminCategories/content', async () => {
  try {
    const response = await adminGetCategories()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminCategoriesSlice = createSlice({
  name: 'adminCategories',
  initialState: {
    isLoading: false,
    adminCategories: []
  },
  reducers: {},

  extraReducers: {
    [adminGetCategoriesContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetCategoriesContent.fulfilled]: (state, action) => {
      state.adminCategories = action.payload
      state.isLoading = false
    },
    [adminGetCategoriesContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewCategory } = adminCategoriesSlice.actions

export default adminCategoriesSlice.reducer