import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../pagesComponents/_common/headerSlice'
import modalSlice from '../pagesComponents/_common/modalSlice'
import rightDrawerSlice from '../pagesComponents/_common/rightDrawerSlice'

//AdminSlices
import adminCategoriesSlice from '../pagesComponents/adminDashboard/categories/categoriesSlice'
import adminSitesSlice from '../pagesComponents/adminDashboard/sites/sitesSlice'
import adminMerchantsSlice from '../pagesComponents/adminDashboard/merchants/merchantsSlice'
import adminVendorsSlice from '../pagesComponents/adminDashboard/vendors/vendorsSlice'
import adminItemsSlice from '../pagesComponents/adminDashboard/items/itemsSlice'
import adminTransactionsSlice from '../pagesComponents/adminDashboard/transactions/transactionsSlice'
// import adminPayoutOptionsSlice from '../pagesComponents/adminDashboard/payoutSettings/payoutOptionsSlice'
import adminPayoutsSlice from '../pagesComponents/adminDashboard/payouts/payoutsSlice'
import adminTicketsSlice from '../pagesComponents/adminDashboard/tickets/ticketsSlice'
import adminTicketsMessagesSlice from '../pagesComponents/adminDashboard/tickets/messageSlice'

// VendorSlices
import sitesSlice from '../pagesComponents/vendorDashboard/sites/sitesSlice'
import itemslice from '../pagesComponents/vendorDashboard/items/itemSlice'
import transactionsSlice from '../pagesComponents/vendorDashboard/transactions/transactionsSlice'
import payoutOptionsSlice from '../pagesComponents/vendorDashboard/payoutSettings/payoutOptionsSlice'
import ticketsSlice from '../pagesComponents/vendorDashboard/tickets/ticketSlice'
import messagesSlice from '../pagesComponents/vendorDashboard/tickets/messageSlice'
import payoutsSlice from '../pagesComponents/vendorDashboard/payouts/payoutsSlice'

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,

  //Admin Reducers
  adminCategories: adminCategoriesSlice,
  adminSites: adminSitesSlice,
  adminMerchants: adminMerchantsSlice,
  adminVendors: adminVendorsSlice,
  adminItems: adminItemsSlice,
  adminTransactions: adminTransactionsSlice,
  // adminPayoutsOptions: adminPayoutOptionsSlice,
  adminPayouts: adminPayoutsSlice,
  adminTickets: adminTicketsSlice,
  adminTicketsMessages: adminTicketsMessagesSlice,

  // Vendor Reducers
  site: sitesSlice,
  item: itemslice,
  transactions: transactionsSlice,
  payoutsoptions: payoutOptionsSlice,
  tickets: ticketsSlice,
  messages: messagesSlice,
  payouts: payoutsSlice,
}

export default configureStore({
  reducer: combinedReducer
})