import axios from 'axios'
import { getHeaders, getHeadersForSendFile } from '../utils/getHeaders'
import toast from 'react-hot-toast'
import { logoutUser } from './logout';

const serialize = (obj, prefix) => {
  const str = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        serialize(v, k) :
        `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
};

export const handleError = (error) => {
  let errorMessage = '';
  if (error.response?.status === 401) {
    errorMessage = 'Unauthorized. Please login again.';
    // Trigger logoutUser function if status is 401
    // logoutUser();
  } else if (error.response?.data?.errors?.full_messages) {
    errorMessage = error.response.data.errors.full_messages.join('. ');
  } else if (error.response?.data?.errors) {
    errorMessage = error.response.data.errors.join('. ');
  } else {
    errorMessage = 'An error occurred';
  }
  toast.error(errorMessage);
};
export const handleSuccess = (message) => {
  toast.success(message || 'Success!')
}



// --------  Admin api  ---------//

// Login
export const loginAdmin = (values) => {
  const body = {
    'email': values.email,
    'password': values.password
  }
  return axios.post('/admins/sign_in', body)
}

// Merchant
export const adminGetMerchants = () => {
  return axios.get('/admins/merchants', { headers: getHeaders() })
}
export const adminCreateMerchant = (values) => {
  const body = {
    'name': values.name,
    'domain': values.domain,
    'description': values.description,
    'public_key': values.public_key,
    'private_key': values.private_key,
    'live_mode': values.live_mode,
    'payment_method': values.payment_method
  };
  return axios.post('/admins/merchants', { merchant: body }, { headers: getHeaders() })
}
export const adminEditMerchant = (values, id) => {
  const body = {
    'name': values.name,
    'domain': values.domain,
    'description': values.description,
    'public_key': values.public_key,
    'private_key': values.private_key,
    'live_mode': values.live_mode,
    'payment_method': values.payment_method
  };
  return axios.put(`/admins/merchants/${id}`, { merchant: body }, { headers: getHeaders() })
}
export const adminDeleteMerchant = (id) => {
  return axios.delete(`/admins/merchants/${id}`, { headers: getHeaders() })
}

// Vendors
export const adminGetVendors = () => {
  return axios.get('admins/vendors', { headers: getHeaders() })
}
export const adminCreateVendor = (values) => {
  const body = {
    'username': values.username,
    'description': values.description,
    'email': values.email,
    'password': values.password,
  }
  return axios.post('admins/vendors', { vendor: body }, { headers: getHeaders() })
}
export const adminEditVendor = (values, id) => {
  const body = {
    'username': values.username,
    'description': values.description,
    'email': values.email,
    'password': values.password,
  };
  return axios.put(`/admins/vendors/${id}`, { vendor: body }, { headers: getHeaders() })
}

export const adminSubmitNewPassword = (values) => {
  return axios.put('/admins/password', values, { headers: getHeaders() })
}
export const adminDeleteVendor = (id) => {
  return axios.delete(`admins/vendors/${id}`, { headers: getHeaders() })
}

// Sites
export const adminGetSites = () => {
  return axios.get('/admins/sites', { headers: getHeaders() })
}
export const adminCreateSite = (values) => {
  const body = {
    'name': values.name,
    'url': values.url,
    'vendor_id': values.vendor_id,
    'category_id': values.category_id,
    'commission': values.commission,
    'paypal_commission': values.paypal_commission,
    'send_webhooks': values.send_webhooks,
    'merchant_ids': values.merchant_ids,
    'allow_paypal': values.allow_paypal,
    'paypal_merchant_id': values.paypal_merchant_id,
    'live_mode': values.live_mode
  };
  if (values.send_webhooks === true) {
    body['webhook_url'] = values.webhook_url;
  }
  return axios.post('/admins/sites', { site: body }, { headers: getHeaders() });
}
export const adminEditSite = (values, siteId) => {
  const body = {
    'name': values.name,
    'url': values.url,
    'vendor_id': values.vendor_id,
    'category_id': values.category_id,
    'commission': values.commission,
    'paypal_commission': values.paypal_commission,
    'send_webhooks': values.send_webhooks,
    "webhook_url": values.webhook_url,
    'merchant_ids': values.merchant_ids,
    'allow_paypal': values.allow_paypal,
    'paypal_merchant_id': values.paypal_merchant_id,
    'live_mode': values.live_mode
  };
  if (values.send_webhooks === false) {
    body['webhook_url'] = '';
  }
  return axios.put(`/admins/sites/${siteId}`, { site: body }, { headers: getHeaders() });
}
export const adminDeleteSite = (id) => {
  return axios.get(`/admins/sites/${id}`, { headers: getHeaders() })
}

// Categories
export const adminGetCategories = () => {
  return axios.get('/admins/categories', { headers: getHeaders() })
}
export const adminCreateCategory = (values) => {
  const body = {
    'name': values.name,
    'commission': values.commission,
  };
  return axios.post('/admins/categories', { category: body }, { headers: getHeaders() })
}
export const adminEditCategory = (values, id) => {
  const body = {
    'name': values.name,
    'commission': values.commission,
  };
  return axios.put(`/admins/categories/${id}`, { category: body }, { headers: getHeaders() });
}

// Items
export const adminGetItems = () => {
  return axios.get('/admins/items', { headers: getHeaders() })
}
export const adminDeleteItem = (id) => {
  return axios.delete(`/admins/items/${id}`, { headers: getHeaders() })
}
export const adminCreateItem = (values) => {
  const body = {
    'name': values.name,
    'price': values.price,
    'currency': values.currency,
    'description': values.description,
    'site_id': values.site_id
  };
  return axios.post('/admins/items', { item: body }, { headers: getHeaders() });
}
export const adminEditItem = (values, itemId) => {
  const body = {
    'name': values.name,
    'price': values.price,
    'currency': values.currency,
    'description': values.description,
    'site_id': values.site_id
  };
  return axios.put(`/admins/items/${itemId}`, { item: body }, { headers: getHeaders() });
}

// Transactions
export const adminGetTransactions = (filters) => {
  const queryParams = {
    page: filters.page,
    search: {
      transaction_type: filters.transactionStatus,
      query: filters.search,
      vendor_id: filters.vendorId,
      merchant_id: filters.merchantId,
      from_date: filters.dateRange?.startDate,
      to_date: filters.dateRange?.endDate,
    }
  };
  // Serialize query parameters
  const queryString = serialize(queryParams);
  return axios.get(`/admins/transactions?${queryString}`, { headers: getHeaders() });
}

// Payouts
export const adminGetPayouts = () => {
  return axios.get(`/admins/payouts`, { headers: getHeaders() })
}
export const adminAddPayout = (values) => {
  const body = {
    'requested_amount_net': values.requested_amount_net,
    'paid_amount_net': values.paid_amount_net,
    'balance_id': values.balance_id,
    'payout_option_id': values.payout_option_id,
    'paid_date': values.paid_date,
    'comment': values.comment,
  };
  return axios.post('/admins/payouts', { payout: body }, { headers: getHeaders() })
}
export const adminUpdatePayout = (values, id) => {
  const body = {
    'requested_amount_net': values.requested_amount_net,
    'paid_amount_net': values.paid_amount_net,
    'payout_option_id': values.payout_option_id,
    'balance_id': values.balance_id,
    'paid_date': values.paid_date,
    'comment': values.comment,
  };
  return axios.put(`/admins/payouts/${id}`, { payout: body }, { headers: getHeaders() })
}
export const adminCompletePayout = (item) => {
  const body = {
    'requested_amount_net': item.requested_amount_net,
    'payout_option_id': item.payout_option,
    'balance_id': item.balance_id
  };
  return axios.put(`/admins/payouts/${item.id}/complete_payout`, { payout: body }, { headers: getHeaders() })
}

// Tickets
export const adminGetTickets = (page) => {
  return axios.get(`/admins/tickets?page=${page}`, { headers: getHeaders() })
}
export const adminCloseTicket = (id) => {
  return axios.delete(`/admins/tickets/${id}`, { headers: getHeaders() })
}
export const adminCreateTicket = (values) => {
  const body = {
    'subject': values.subject,
    'message': {
      'body': values.message
    }
  };
  return axios.post('/admins/tickets', { ticket: body }, { headers: getHeaders() })
}
export const adminGetTicketMessages = (id) => {
  return axios.get(`/admins/tickets/${id}`, { headers: getHeaders() })
}
export const adminSendMessageInTicket = (values, ticketId) => {
  const body = {
    'message': {
      'body': values.message
    }
  };
  return axios.put(`/admins/tickets/${ticketId}`, { ticket: body }, { headers: getHeaders() })
}
// export const sendMessageInTicket = (values, ticketId, files) => {
//   const formData = new FormData();
//   files.forEach((file) => {
//     formData.append('screenshots', file);
//   });
//   formData.append('message', values.message);
//   return axios.put(`/vendors/tickets/${ticketId}`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' }, }
//   )
// }

// Admin - Payout options
export const getAdminPayoutOptions = () => {
  return axios.get('/admins/payout_options', { headers: getHeaders() })
}
export const deleteAdminPayoutOptions = (id) => {
  return axios.delete(`/admins/payout_options/${id}`, { headers: getHeaders() })
}
export const createAdminPayoutOption = (values) => {
  const body = {
    'name': values.name,
    'payout_method': values.payout_method,
    'payout_address': values.payout_address,
  };
  return axios.post('/admins/payout_options', { payout_option: body }, { headers: getHeaders() })
}
export const updateAdminPayoutOption = (values, id) => {
  const body = {
    'name': values.name,
    'payout_method': values.payout_method,
    'payout_address': values.payout_address,
  };
  return axios.put(`/admins/payout_options/${id}`, { payout_option: body }, { headers: getHeaders() })
}






// --------  Vendor api  ---------//

// Account
export const registerVendor = (values) => {
  return axios.post('/vendors', values)
}
export const loginVendor = (values) => {
  const body = {
    'email': values.email,
    'password': values.password,
    'otp_attempt': values.otp_attempt
  }
  return axios.post('/vendors/sign_in', body)
}

export const vendorLogout = () => {
  return axios.delete('vendors/sign_out', { headers: getHeaders() })
}
export const requestPasswordReset = (values) => {
  return axios.post('/vendors/password', values)
}
export const submitNewPassword = (values) => {
  return axios.put('/vendors/password', values, { headers: getHeaders() })
}

export const update2faSettings = (values) => {
  return axios.post('/vendors/two_factor_settings', values, { headers: getHeaders() })
}

export const getQRcode = () => {
  return axios.get('/vendors/two_factor_settings/get_qr', { headers: getHeaders() })
}

export const verifyCaptcha = (code) => {
  return axios.post('/verify_captcha', { 'captcha_response': code })
}

export const delete2faSettings = (values) => {
  return axios.delete(`/vendors/two_factor_settings/?code=${values.code}`, { headers: getHeaders() })
}

export const getBackupCodes = (values) => {
  return axios.get('/vendors/two_factor_settings/generate_backup_codes', values, { headers: getHeaders() })
}

// Sites
export const getSites = () => {
  return axios.get('/vendors/sites', { headers: getHeaders() })
}
// export const createSite = (values, ) => {
//   const body = {
//     'name': values.name,
//     'url': values.url,
//     // 'live_mode': false,
//     'category_id': values.category_id,
//     'send_webhooks': values.send_webhooks
//   };
//   if (values.send_webhooks === true) {
//     body['webhook_url'] = values.webhook_url;
//   }
//   return axios.post('/vendors/sites', { site: body }, { headers: getHeaders() });
// }
export const createSite = (values, file) => {
  const formData = new FormData();
  formData.append('site[name]', values.name);
  formData.append('site[url]', values.url);
  formData.append('site[category_id]', values.category_id);
  formData.append('site[send_webhooks]', values.send_webhooks);
  formData.append('site[successful_payment_url]', values.successful_payment_url);
  formData.append('site[failed_payment_url]', values.failed_payment_url);
  formData.append('site[send_telegram_notifications]', values.send_telegram_notifications);
  formData.append('site[telegram_user_id]', values.telegram_user_id);
  if (file) {
    formData.append('site[logo]', file);
  }
  if (values.send_webhooks === true) {
    formData.append('site[webhook_url]', values.webhook_url);
  }
  return axios.post('/vendors/sites', formData, { headers: getHeadersForSendFile() });
};

export const editSite = (values, siteId, file) => {
  const formData = new FormData();
  // Append form data
  formData.append('site[name]', values.name);
  formData.append('site[url]', values.url);
  formData.append('site[send_webhooks]', values.send_webhooks);
  formData.append('site[successful_payment_url]', values.successful_payment_url);
  formData.append('site[failed_payment_url]', values.failed_payment_url);
  formData.append('site[send_telegram_notifications]', values.send_telegram_notifications);
  formData.append('site[telegram_user_id]', values.telegram_user_id);
  if (file) {
    formData.append('site[logo]', file);
  }
  if (values.send_webhooks === true) {
    formData.append('site[webhook_url]', values.webhook_url);
  }
  return axios.put(`/vendors/sites/${siteId}`, formData, { headers: getHeadersForSendFile() });
}
export const deleteSite = (id) => {
  return axios.delete(`/vendors/sites/${id}`, { headers: getHeaders() })
}

// Categories
export const getCategories = () => {
  return axios.get('/vendors/categories', { headers: getHeaders() })
}


// Items
export const getItems = () => {
  return axios.get('/vendors/items', { headers: getHeaders() })
}
export const deleteItem = (id) => {
  return axios.delete(`/vendors/items/${id}`, { headers: getHeaders() })
}
export const createItem = (values) => {
  const body = {
    'name': values.name,
    'price': values.price,
    'currency': values.currency,
    'description': values.description,
    'site_id': values.site_id
  };
  return axios.post('/vendors/items', { item: body }, { headers: getHeaders() });
}
export const editItem = (values, itemId) => {
  const body = {
    'name': values.name,
    'price': values.price,
    'currency': values.currency,
    'description': values.description,
    'site_id': values.site_id
  };
  return axios.put(`/vendors/items/${itemId}`, { item: body }, { headers: getHeaders() });
}


// Transactions
export const getTransactions = (filters) => {
  const queryParams = {
    page: filters.page,
    search: {
      transaction_type: filters.transactionStatus,
      query: filters.search,
      from_date: filters.dateRange?.startDate,
      to_date: filters.dateRange?.endDate,
    }
  };
  // Serialize query parameters
  const queryString = serialize(queryParams);
  return axios.get(`/vendors/transactions?${queryString}`, { headers: getHeaders() });
}


// Payouts
export const getPayouts = (page) => {
  return axios.get(`/vendors/payouts`, { headers: getHeaders() })
}
export const addPayout = (values) => {
  const body = {
    'requested_amount_net': values.requested_amount_net,
    'payout_option_id': values.payout_option_id,
    'balance_id': values.balance_id
  };
  return axios.post('/vendors/payouts', { payout: body }, { headers: getHeaders() })
}
export const updatePayout = (values, id) => {
  const body = {
    'requested_amount_net': values.requested_amount_net,
    'payout_option_id': values.payout_option_id,
    'balance_id': values.balance_id
  };
  return axios.put(`/vendors/payouts/${id}`, { payout: body }, { headers: getHeaders() })
}
export const deletePayout = (id) => {
  return axios.delete(`/vendors/payouts/${id}`, { headers: getHeaders() })
}


// Payout options
export const getPayoutOptions = () => {
  return axios.get('/vendors/payout_options', { headers: getHeaders() })
}
export const deletePayoutOptions = (id) => {
  return axios.delete(`/vendors/payout_options/${id}`, { headers: getHeaders() })
}
export const createPayoutOption = (values) => {
  const body = {
    'name': values.name,
    'payout_method': values.payout_method,
    'payout_address': values.payout_address,
  };
  return axios.post('/vendors/payout_options', { payout_option: body }, { headers: getHeaders() })
}
export const updatePayoutOption = (values, id) => {
  const body = {
    'name': values.name,
    'payout_method': values.payout_method,
    'payout_address': values.payout_address,
  };
  return axios.put(`/vendors/payout_options/${id}`, { payout_option: body }, { headers: getHeaders() })
}


// Tickets
export const getTickets = (page) => {
  return axios.get(`/vendors/tickets?page=${page}`, { headers: getHeaders() })
}
export const createTicket = (values) => {
  const body = {
    'subject': values.subject,
    'message': {
      'body': values.message
    }
  };
  return axios.post('/vendors/tickets', { ticket: body }, { headers: getHeaders() })
}
export const getTicketMessages = (id) => {
  return axios.get(`/vendors/tickets/${id}`, { headers: getHeaders() })
}
export const sendMessageInTicket = (values, ticketId) => {
  const body = {
    'message': {
      'body': values.message
    }
  };
  return axios.put(`/vendors/tickets/${ticketId}`, { ticket: body }, { headers: getHeaders() })
}
// export const sendMessageInTicket = (values, ticketId, files) => {
//   const formData = new FormData();
//   files.forEach((file) => {
//     formData.append('screenshots', file);
//   });
//   formData.append('message', values.message);
//   return axios.put(`/vendors/tickets/${ticketId}`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' }, }
//   )
// }


// Make payments api
export const getClientSecret = (product, price, ipAddress, customField, isPayPal) => {
  let queryParams = `product=${product}&user_ip=${ipAddress}`;
  if (isPayPal) {
    queryParams += '&method=paypal';
  }

  const isPriceValid = (price) => /^-?\d+(\.\d+)?$/.test(price);

  if (price && isPriceValid(price)) {
    // Convert to number, fix to 2 decimal places, and convert back to string
    const roundedPrice = parseFloat(price).toFixed(2);
    queryParams += `&price=${roundedPrice}`;
  }

  if (customField) {
    queryParams += `&custom_field=${customField}`;
  }

  return axios.get(`/get_client_secret?${queryParams}`);
}



// Complete PayPal transaction

export const completePayPalTransaction = (client_secret) => {
  return axios.post('/complete_paypal_order', { order: client_secret });
}