import Cookies from 'js-cookie'

export const getHeaders = (navigate) => {
  const accessToken = Cookies.get('headers_accessToken');
  const client = Cookies.get('headers_client');
  const uid = Cookies.get('headers_uid');

  if (!accessToken) {
    // Redirect to login page
    navigate('/login');
    return {}; // Return empty headers to prevent API calls
  }

  return {
    'Content-Type': 'application/json',
    'access-token': accessToken,
    client,
    uid,
  };
};


export function getHeadersForSendFile(navigate) {
  const accessToken = Cookies.get('headers_accessToken');
  const client = Cookies.get('headers_client');
  const uid = Cookies.get('headers_uid');

  if (!accessToken) {
    // Redirect to login page
    navigate('/login');
    return {}; // Return empty headers to prevent API calls
  }

  return {
    "Content-Type": 'form-data',
    'access-token': accessToken,
    client,
    uid,
  };
}
