import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getItems, handleError } from '../../../sevices/apiService'

export const getItemsContent = createAsyncThunk('/items/content', async () => {
  try {
    const response = await getItems()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const itemsSlice = createSlice({
  name: 'items',
  initialState: {
    isLoading: false,
    items: []
  },
  reducers: {},

  extraReducers: {
    [getItemsContent.pending]: state => {
      state.isLoading = true
    },
    [getItemsContent.fulfilled]: (state, action) => {
      state.items = action.payload
      state.isLoading = false
    },
    [getItemsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewitem } = itemsSlice.actions

export default itemsSlice.reducer