import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getPayouts, handleError } from '../../../sevices/apiService'

export const getPayoutsContent = createAsyncThunk('/payouts/content', async () => {
  try {
    const response = await getPayouts()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const payoutsSlice = createSlice({
  name: 'payouts',
  initialState: {
    isLoading: false,
    payouts: []
  },
  reducers: {},

  extraReducers: {
    [getPayoutsContent.pending]: state => {
      state.isLoading = true
    },
    [getPayoutsContent.fulfilled]: (state, action) => {
      state.payouts = action.payload
      state.isLoading = false
    },
    [getPayoutsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewPayout } = payoutsSlice.actions

export default payoutsSlice.reducer