import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getTicketMessages, handleError } from '../../../sevices/apiService';

export const getMessagesInTicketContent = createAsyncThunk('/messages/content', async (id) => {
  try {
    const response = await getTicketMessages(id);
    return response.data;
  } catch (error) {
    handleError(error);
  }
});

export const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    isLoading: false,
    messages: [],
  },
  reducers: {},
  extraReducers: {
    [getMessagesInTicketContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getMessagesInTicketContent.fulfilled]: (state, action) => {
      state.messages = action.payload;
      state.isLoading = false;
    },
    [getMessagesInTicketContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default messagesSlice.reducer;