import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetPayouts, handleError } from '../../../sevices/apiService'

export const adminGetPayoutsContent = createAsyncThunk('/payouts/content', async () => {
  try {
    const response = await adminGetPayouts()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminPayoutsSlice = createSlice({
  name: 'adminPayouts',
  initialState: {
    isLoading: false,
    adminPayouts: []
  },
  reducers: {},

  extraReducers: {
    [adminGetPayoutsContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetPayoutsContent.fulfilled]: (state, action) => {
      state.adminPayouts = action.payload
      state.isLoading = false
    },
    [adminGetPayoutsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewPayout } = adminPayoutsSlice.actions

export default adminPayoutsSlice.reducer