import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getSites, handleError } from '../../../sevices/apiService'

export const getSitesContent = createAsyncThunk('/sites/content', async () => {
  try {
    const response = await getSites()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const sitesSlice = createSlice({
  name: 'sites',
  initialState: {
    isLoading: false,
    sites: []
  },
  reducers: {},

  extraReducers: {
    [getSitesContent.pending]: state => {
      state.isLoading = true
    },
    [getSitesContent.fulfilled]: (state, action) => {
      // console.log('Received data:', action.payload) //пейлоад що прилетів
      state.sites = action.payload
      state.isLoading = false
    },
    [getSitesContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewSite } = sitesSlice.actions

export default sitesSlice.reducer