import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTickets, handleError } from '../../../sevices/apiService';

export const getTicketsContent = createAsyncThunk('/tickets/content', async (page) => {
  try {
    const response = await getTickets(page);
    return response.data;
  } catch (error) {
    handleError(error);
  }
});

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    isLoading: false,
    tickets: [],
    currentTicketId: null,
  },
  reducers: {
    setCurrentTicketId: (state, action) => {
      state.currentTicketId = action.payload;
    },
  },
  extraReducers: {
    [getTicketsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [getTicketsContent.fulfilled]: (state, action) => {
      state.tickets = action.payload;
      state.isLoading = false;
    },
    [getTicketsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default ticketsSlice.reducer;
