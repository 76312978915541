import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetTransactions, handleError } from '../../../sevices/apiService'

export const adminGetTransactionsContent = createAsyncThunk('/transactions/content', async (filters) => {
  try {
    const response = await adminGetTransactions(filters)
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const transactionsSlice = createSlice({
  name: 'adminTransactions',
  initialState: {
    isLoading: false,
    transactions: []
  },
  reducers: {},

  extraReducers: {
    [adminGetTransactionsContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetTransactionsContent.fulfilled]: (state, action) => {
      state.transactions = action.payload
      state.isLoading = false
    },
    [adminGetTransactionsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export default transactionsSlice.reducer