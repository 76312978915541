import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetSites, handleError } from '../../../sevices/apiService'

export const adminGetSitesContent = createAsyncThunk('/adminSites/content', async () => {
  try {
    const response = await adminGetSites()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminSitesSlice = createSlice({
  name: 'adminSites',
  initialState: {
    isLoading: false,
    sites: []
  },
  reducers: {},

  extraReducers: {
    [adminGetSitesContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetSitesContent.fulfilled]: (state, action) => {
      state.sites = action.payload
      state.isLoading = false
    },
    [adminGetSitesContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewSite } = adminSitesSlice.actions

export default adminSitesSlice.reducer