import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetMerchants, handleError } from '../../../sevices/apiService'

export const adminGetMerchantsContent = createAsyncThunk('/adminMerchants/content', async () => {
  try {
    const response = await adminGetMerchants()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminMerchantsSlice = createSlice({
  name: 'adminMerchants',
  initialState: {
    isLoading: false,
    adminMerchants: []
  },
  reducers: {},

  extraReducers: {
    [adminGetMerchantsContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetMerchantsContent.fulfilled]: (state, action) => {
      // console.log('Received data:', action.payload) //пейлоад що прилетів
      state.adminMerchants = action.payload
      state.isLoading = false
    },
    [adminGetMerchantsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewMerchant } = adminMerchantsSlice.actions

export default adminMerchantsSlice.reducer