import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { adminGetVendors, handleError } from '../../../sevices/apiService'

export const adminGetVendorsContent = createAsyncThunk('/adminVendors/content', async () => {
  try {
    const response = await adminGetVendors()
    return response.data
  } catch (error) {
    handleError(error)
  }
})

export const adminVendorsSlice = createSlice({
  name: 'adminVendors',
  initialState: {
    isLoading: false,
    adminVendors: []
  },
  reducers: {},

  extraReducers: {
    [adminGetVendorsContent.pending]: state => {
      state.isLoading = true
    },
    [adminGetVendorsContent.fulfilled]: (state, action) => {
      // console.log('Received data:', action.payload) //пейлоад що прилетів
      state.adminVendors = action.payload
      state.isLoading = false
    },
    [adminGetVendorsContent.rejected]: state => {
      state.isLoading = false
    },
  }
})

export const { addNewVendor } = adminVendorsSlice.actions

export default adminVendorsSlice.reducer