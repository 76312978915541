import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { adminGetTickets, handleError } from '../../../sevices/apiService';

export const adminGetTicketsContent = createAsyncThunk('/tickets/content', async () => {
  try {
    const response = await adminGetTickets();
    return response.data;
  } catch (error) {
    handleError(error);
  }
});

export const adminTicketsSlice = createSlice({
  name: 'adminTickets',
  initialState: {
    isLoading: false,
    tickets: [],
    currentTicketId: null,
  },
  reducers: {
    setCurrentTicketId: (state, action) => {
      state.currentTicketId = action.payload;
    },
  },
  extraReducers: {
    [adminGetTicketsContent.pending]: (state) => {
      state.isLoading = true;
    },
    [adminGetTicketsContent.fulfilled]: (state, action) => {
      state.tickets = action.payload;
      state.isLoading = false;
    },
    [adminGetTicketsContent.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export default adminTicketsSlice.reducer;
