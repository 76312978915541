import Cookies from 'js-cookie'
import { logoutUser } from '../sevices/logout'

const checkAuth = () => {
  const TOKEN = Cookies.get('headers_accessToken')
  const USER_TYPE = Cookies.get('user_type')
  const PUBLIC_ROUTES = ["login", "borodati", "forgot-password", "register", "pay"]

  const isPublicPage = PUBLIC_ROUTES.some(r => window.location.href.includes(r))

  if (!TOKEN && !USER_TYPE && !isPublicPage) {
    logoutUser();
    window.location.href = '/login'

    return;
  } else {
    return {
      token: TOKEN,
      user_type: USER_TYPE
    };
  }
}

export default checkAuth