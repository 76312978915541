import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
// import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import { CookiesProvider } from 'react-cookie'
import axios from 'axios'
// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const AdminAuth = lazy(() => import('./pages/AdminAuth'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const NewPassword = lazy(() => import('./pages/NewPassword'))
const Register = lazy(() => import('./pages/Register'))
const Pay = lazy(() => import('./pages/Pay/Pay'))
const PayPal = lazy(() => import('./pages/PayPal/PayPal'))
const Success = lazy(() => import('./pages/Success'))

axios.defaults.headers = {
  'Content-Type': 'application/json',
  // "Access-Control-Allow-Origin": "*",
}

// Initializing different libraries
initializeApp()

// Check for login and initialize axios
const { token, user_type } = checkAuth()

function App() {

  useEffect(() => {
    // themeChange(false)
    checkAuth();
  }, [])

  return (
    <>
      <CookiesProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/borodati" element={<AdminAuth />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/register" element={<Register />} />
            <Route path="/pay" element={<Pay />} />
            <Route path="/paypal" element={<PayPal />} />
            <Route path="/success" element={<Success />} />

            {/* Place new routes over this */}
            <Route path="/app/*" element={<Layout />} />

            {user_type === 'vendor' ? (
              <Route
                path="*"
                element={<Navigate to={token ? "/app/sites" : "/login"} replace />}
              />
            ) : (
              user_type === 'admin' ? (
                <Route
                  path="*"
                  element={<Navigate to={token ? "/app/borodati/vendors" : "/login"} replace />}
                />
              ) : null
            )}


          </Routes>
        </Router>
      </CookiesProvider>
    </>
  )
}

export default App
